import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout/Layout";
import RichText from "../components/RichText/RichText";

import { workshopsSection, content } from "./workshops.module.scss";

const Workshops = ({ data }) => {
  const { contentfulWorkshopsPage } = data;
  const { pageTitle, pageContent } = contentfulWorkshopsPage;
  return (
    <Layout>
      <section className={`grid-wrapper ${workshopsSection}`}>
        <h1>{pageTitle}</h1>
        <RichText richText={pageContent} className={content} />
      </section>
    </Layout>
  );
};

export default Workshops;

export const workshopsQuery = graphql`
  query {
    contentfulWorkshopsPage {
      pageTitle
      pageContent {
        raw
        references {
          ... on ContentfulButton {
            contentful_id
            __typename
            text
            link
          }
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
            description
          }
          ... on ContentfulImageWithCaption {
            contentful_id
            __typename
            image {
              gatsbyImageData
              description
            }
            caption {
              raw
            }
          }
        }
      }
    }
  }
`;
